import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFetcher } from '@not-remix-run/react';
import Modal from '@justpark/ui/src/components/Modal';
import Typography from '@justpark/ui/src/components/Typography';

import CrossIcon from '@justpark/ui/src/components/Icons/CrossIcon';
import Button from '@justpark/ui/src/components/Button';
import TextArea from '@justpark/ui/src/components/TextArea';
import styles from '../../../../static/scss/components/dashboard/messages/sendMessageModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  bookingId?: number | null;
};

const cypress = 'send-message-modal';

const SendMessageModal = ({
  isOpen,
  onClose,

  bookingId = null
}: Props) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');

  const fetcher = useFetcher({ key: 'send-message' });
  const isLoading = fetcher.state !== 'idle';

  useEffect(() => {
    if (!isOpen) {
      setMessage('');
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      id="send-message-modal"
      portalParent="#portal"
    >
      <div className={styles.modal} data-cy={cypress}>
        <fetcher.Form method="post">
          <div className={styles.container}>
            <div className={styles.header}>
              <Typography className={styles.heading} size="h2" bold>
                {t('messages:sendMessage', 'Send a message')}
              </Typography>
              <CrossIcon className={styles.close} onClick={onClose} />
            </div>
            <div>
              <Typography size="primary" color="light">
                <div>
                  <Trans i18nKey="messages:sendMessageConversationInfo">
                    Please keep as much of the conversation online using our
                    messaging system so that a full record of your
                    correspondence is available to our customer support team,
                    enabling them to help you in the future.
                  </Trans>
                </div>
              </Typography>

              <Typography bold size="primary" className={styles.text}>
                <div>
                  <Trans i18nKey="messages:sendMessageConversationExtraInfo">
                    Discussion about a parking space that takes place offline
                    are not eligible for our driver protection guarantee.
                  </Trans>
                </div>
              </Typography>
            </div>
            {bookingId && (
              <input type="hidden" name="bookingId" value={bookingId} />
            )}
            <TextArea
              onChange={(value) => setMessage(value)}
              className={styles.input}
              cypress={`${cypress}-input`}
              name="message"
            >
              {message}
            </TextArea>
          </div>
          <div className={styles.footer}>
            <Button
              modifier="secondary"
              outline
              className={styles.button}
              onClick={onClose}
            >
              {t('cta:cancel', 'Cancel')}
            </Button>
            <Button
              className={styles.button}
              cypress={`${cypress}-send`}
              loading={isLoading}
              type="submit"
              disabled={!message}
            >
              {t('cta:send', 'Send')}
            </Button>
          </div>
        </fetcher.Form>
      </div>
    </Modal>
  );
};

export default SendMessageModal;
