import React from 'react';

import Typography from '@justpark/ui/src/components/Typography';

import styles from '../../../static/scss/components/noResults.module.scss';

type Props = {
  headerText: string;
  subText: string;
  image: React.ReactNode;
};
const NoResults = ({ image, headerText, subText }: Props) => (
  <div className={styles.container}>
    <div className={styles.illustration}>{image}</div>
    <Typography size="h2" color="light" bold className={styles.text}>
      {headerText}
    </Typography>
    <Typography size="secondary" color="light">
      {subText}
    </Typography>
  </div>
);

export default NoResults;
