// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import ChevronIcon from "../Icons/ChevronIcon";
import "./sortIcons.scss";

type Props = {
  direction: "ASC" | "DESC" | null;
  className?: string;
  active?: boolean;
};
const SortIcons = ({ direction, className = "", active = false }: Props) => {
  const baseClass = "c-sort-icons";
  const directionClass = bem(baseClass, null, {
    direction,
    active
  });
  return (
    <div className={`${directionClass} ${className}`}>
      <ChevronIcon
        width={8}
        height={8}
        direction="up"
        className={bem(baseClass, "chevron")}
      />
      <ChevronIcon
        width={8}
        height={8}
        direction="down"
        className={bem(baseClass, "chevron")}
      />
    </div>
  );
};

export default SortIcons;
