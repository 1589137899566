// @ts-nocheck
import React, { useCallback, useEffect, useRef } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import SearchIcon from "../../svg/search.svg";
import "./searchInput.scss";
import InputBox from "../InputBox";

type SearchProps = {
  className?: string;
  cypress?: string | null;
  /**
   * The label for the input.
   *
   * As the input is displayed without a visible label, this is an accessibility
   * prop and MUST be set. It should describe what the input is for. If no
   * placeholder text is given, this label will also be used for the placeholder
   * text.
   */
  label: string;
  /**
   * A callback to execute when the input changes.
   *
   * @param value - The current value of the input
   */
  onChange?: (value: string) => any;
  /**
   * The placeholder text.
   *
   * This SHOULD be a hint about the data to be entered. Remember that
   * placeholder text is generally hidden from accessibility tools and will NOT
   * be translated!
   *
   * If you don't set this, the label will be used as the placeholder text. You
   * can prevent this by passing an empty string.
   */
  placeholder?: string;
  value?: string;
  /**
   * A search event should happen in response to this callback being called. If
   * you want to, of course.
   */
  onSearch?: () => void;
  onFocus?: () => void;
  inputMode?: "text" | "numeric";
  type?: "text" | "number";
  showLabel?: boolean;
};
type Props = SearchProps & {
  disableAutoSearch: boolean;
};
const baseClass = "c-search";
const useAutoSearch = (value: string, onSearch: Props["onSearch"]) => {
  const isInitialRender = useRef(true);
  const onSearchRef = useRef();
  onSearchRef.current = onSearch;
  const timeout = useRef<number | null>(null);
  const clearTimeout = useCallback(() => {
    if (timeout.current !== null) {
      window.clearTimeout(timeout.current);
      timeout.current = null;
    }
  }, []);

  // When the user presses Enter clear the timeout and call the onSearch prop callback
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        clearTimeout();
        onSearchRef.current();
      }
    },
    [clearTimeout]
  );
  useEffect(() => {
    if (!isInitialRender.current) {
      const timeoutms = value.length > 0 && value.length < 4 ? 1500 : 750;
      clearTimeout();
      // if the value change comes from outside this component, then onSearch() will
      // still fire. This timeout shouldn't be the responsibility of this component.
      timeout.current = setTimeout(() => {
        onSearchRef.current();
      }, timeoutms);
    }
    isInitialRender.current = false;
    return () => {
      // we need to clear the timeout when the SearchInput unmounts
      clearTimeout();
    };
  }, [clearTimeout, value]);
  return [onKeyDown];
};
const AutoSearchInput = ({
  className,
  cypress,
  label,
  onChange,
  placeholder,
  value,
  onSearch,
  onFocus,
  inputMode,
  type,
  showLabel
}: SearchProps) => {
  const [onKeyDown] = useAutoSearch(value, onSearch);
  const showClearButton = value.length > 0;
  return (
    <InputBox
      className={className}
      cypress={cypress}
      label={label}
      showLabel={showLabel}
      icon={<SearchIcon />}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      showClearButton={showClearButton}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      inputMode={inputMode}
      type={type}
    />
  );
};
const ManualSearchInput = ({
  className,
  cypress,
  label,
  onChange,
  placeholder,
  value,
  onSearch,
  onFocus,
  inputMode,
  type,
  showLabel
}: SearchProps) => {
  const showClearButton = value.length > 0;
  const onPressEnter = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };
  return (
    <InputBox
      className={className}
      cypress={cypress}
      label={label}
      showLabel={showLabel}
      icon={<SearchIcon />}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      showClearButton={showClearButton}
      onKeyDown={onPressEnter}
      onFocus={onFocus}
      inputMode={inputMode}
      type={type}
    />
  );
};

/**
 * Renders a search input field. That's it.
 */
const SearchInput = ({
  className = "",
  cypress = null,
  label,
  onChange = () => {},
  placeholder = null,
  value = "",
  onSearch = () => {},
  disableAutoSearch = false,
  onFocus = () => {},
  inputMode = "text",
  type = "text",
  showLabel = false
}: Props) => {
  return (
    <div
      className={`${bem(baseClass, null, {
        "disable-auto-search": disableAutoSearch
      })} ${className}`}
      data-cy={cypress}
    >
      {disableAutoSearch ? (
        <ManualSearchInput
          className={bem(baseClass, "input")}
          cypress={cypress === null ? null : `${cypress}-search`}
          label={label}
          onSearch={onSearch}
          onChange={onChange}
          placeholder={typeof placeholder === "string" ? placeholder : label}
          value={value}
          onFocus={onFocus}
          inputMode={inputMode}
          type={type}
          showLabel={showLabel}
        />
      ) : (
        <AutoSearchInput
          className={bem(baseClass, "input")}
          cypress={cypress === null ? null : `${cypress}-search`}
          label={label}
          onSearch={onSearch}
          onChange={onChange}
          placeholder={typeof placeholder === "string" ? placeholder : label}
          value={value}
          onFocus={onFocus}
          inputMode={inputMode}
          type={type}
          showLabel={showLabel}
        />
      )}
    </div>
  );
};

export default SearchInput;
