import React from 'react';
import Typography from '@justpark/ui/src/components/Typography';
import TableRow from '@justpark/ui/src/components/TableRow';
import TableCell from '@justpark/ui/src/components/TableCell';

import { Price } from '@justpark/api/src/types';
import PillLabel from '@justpark/ui/src/components/PillLabel';
import styles from '../../../../static/scss/components/dashboard/messages/messagesTableRow.module.scss';

export type Transaction = {
  id: number;
  type: string;
  affectingDate: string;
  description: string;
  amount: Price;
};

type Props = {
  transaction: Transaction;
};

const TransactionsTableRow = ({
  transaction: { type, affectingDate, description, amount, id }
}: Props) => (
  <TableRow className={styles.row}>
    <TableCell className={styles.mobileHidden}>
      <Typography size="secondary" color="text-dark">
        {id}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography size="secondary" color="text-dark">
        {affectingDate}
      </Typography>
    </TableCell>
    <TableCell>
      <PillLabel
        className={styles.pill}
        modifier={
          type === 'Booking' || type === 'Payment' ? undefined : 'secondary'
        }
        label={`${type.charAt(0).toUpperCase().concat(type.slice(1))}`}
        color="primary-inverse"
      />
    </TableCell>
    <TableCell className={styles.mobileHidden} stretch>
      <Typography size="secondary" color="text-dark">
        {description}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography size="secondary" color="text-dark">
        {amount.formatted}
      </Typography>
    </TableCell>
  </TableRow>
);

export default TransactionsTableRow;
