// @ts-nocheck
import * as React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import type { Node } from "react";
import "./tableRow.scss";

type Props = {
  children: Node;
  className?: string;
  cypress?: string | null;
  onClick?: () => any | null;
  background?: "white" | "error" | "green";
};

const TableRow = ({
  children,
  className = "",
  cypress = null,
  onClick = null,
  background = "white"
}: Props) => {
  const clickable = typeof onClick === "function";
  return (
    <tr
      className={`${bem("c-table-row", null, {
        clickable,
        background
      })} ${className}`}
      data-cy={cypress}
      onClick={onClick}
    >
      {children}
    </tr>
  );
};

export default TableRow;
