import React from 'react';
import bem from '@justpark/helpers/src/bem/bem';
import SideNavigation from './SideNavigation';

import '../../../static/scss/components/dashboard/dashboardPageWrapper.scss';

type Props = {
  children?: any;
  className?: string;
  modifier?: string;
  cypress?: string;
};

const baseClass = 'c-dashboard';

const DashboardPageWrapper = ({
  children,
  className = '',
  modifier = '',
  cypress
}: Props) => (
  <div className={baseClass} data-cy={cypress}>
    <div className={bem(baseClass, 'sideNavigation-container')}>
      <SideNavigation />
    </div>

    <div
      className={`c-dashboard__page c-dashboard__page--${modifier} ${className}`}
    >
      {children}
    </div>
  </div>
);

export default DashboardPageWrapper;
