import React from 'react';
import bem from '@justpark/helpers/src/bem/bem';
import { NavLink } from '@not-remix-run/react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import LinkButton from '@justpark/ui/src/components/LinkButton';
import LegacyLink from '../Common/LegacyLink';
import { selectIsAppFeatureLimited } from '../../stores/partner';
import { logout } from '../../stores/auth';
import useDarkly from '../../hooks/useDarkly';

const SideNavigation = () => {
  const isSpaceOwner = useSelector((state) => state.auth?.user?.isSpaceOwner);
  const hasSeasonTickets = useSelector(
    (state) => state.auth?.user?.hasSeasonTicket
  );
  const isAppFeatureLimited = useSelector(selectIsAppFeatureLimited);
  const enableNewMessagesPage = useDarkly('enable-jp-web-route-messages');
  const enableNewBillingPage = useDarkly(
    'enable-jp-web-route-billings-and-withdrawals'
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const baseClass = 'c-sideNavigation';
  const MessageLink = enableNewMessagesPage ? NavLink : LegacyLink;
  const BillingLink = enableNewBillingPage ? NavLink : LegacyLink;

  return (
    <ul className={baseClass}>
      <li className={`${baseClass}__item`}>
        <NavLink
          className={({ isActive }) => bem(baseClass, 'link', { isActive })}
          to="/dashboard/bookings/made"
        >
          {t('navigation:bookingsMade', 'Bookings made')}
        </NavLink>
      </li>

      {isSpaceOwner && !isAppFeatureLimited && (
        <li className={`${baseClass}__item`}>
          <NavLink
            className={({ isActive }) => bem(baseClass, 'link', { isActive })}
            to="/dashboard/bookings/received"
          >
            {t('navigation:bookingsReceived', 'Bookings received')}
          </NavLink>
        </li>
      )}

      {hasSeasonTickets && !isAppFeatureLimited && (
        <li className={`${baseClass}__item`}>
          <NavLink
            className={({ isActive }) => bem(baseClass, 'link', { isActive })}
            to="/dashboard/season-tickets"
          >
            {t('navigation:seasonTickets', 'Tickets & permits')}
          </NavLink>
        </li>
      )}

      {!isAppFeatureLimited && (
        <li className={`${baseClass}__item`}>
          <MessageLink
            to="/dashboard/messaging/"
            className={({ isActive }) => bem(baseClass, 'link', { isActive })}
          >
            {t('navigation:messages', 'Messages')}
          </MessageLink>
        </li>
      )}

      {isSpaceOwner && !isAppFeatureLimited && (
        <li className={`${baseClass}__item`}>
          <NavLink
            className={({ isActive }) => bem(baseClass, 'link', { isActive })}
            to="/dashboard/parking-spaces"
          >
            {t('navigation:parkingSpaces', 'Manage my spaces')}
          </NavLink>
        </li>
      )}

      {!isAppFeatureLimited && (
        <li className={`${baseClass}__item`}>
          <BillingLink
            to="/dashboard/billing/"
            className={({ isActive }) => bem(baseClass, 'link', { isActive })}
          >
            {t('navigation:billing', 'Billing & withdrawals')}
          </BillingLink>
        </li>
      )}

      <li className={`${baseClass}__item`}>
        <NavLink
          className={({ isActive }) => bem(baseClass, 'link', { isActive })}
          to="/dashboard/profile"
        >
          {t('navigation:profile', 'My profile')}
        </NavLink>
      </li>

      <li className={`${baseClass}__item`}>
        <LinkButton
          className={bem(baseClass, 'link')}
          type="button"
          onClick={() => {
            dispatch(logout());
          }}
        >
          {t('cta:logOut', 'Log out')}
        </LinkButton>
      </li>
    </ul>
  );
};

export default SideNavigation;
