import React, { lazy } from 'react';
import loadable from '@loadable/component';
import { compose } from 'redux';

const Loading = () => <div className="p-loading" />;

export const Home = lazy(
  () => import(/* webpackChunkName: "page-home" */ `./Home`)
);

export const Landing = loadable(
  () => import(/* webpackChunkName: "page-landing" */ './Landing'),
  {
    fallback: <Loading />
  }
);

export const SeasonTicket = loadable(
  () => import(/* webpackChunkName: "page-season-ticket" */ './SeasonTicket'),
  {
    fallback: <Loading />
  }
);

export const Promo = loadable(
  () => import(/* webpackChunkName: "page-promo" */ './Promo'),
  {
    fallback: <Loading />
  }
);

export const SearchResults = loadable(
  () => import(/* webpackChunkName: "page-search-results" */ './Search'),
  {
    fallback: <Loading />
  }
);

export const PartnerSearchResults = loadable(
  () =>
    import(
      /* webpackChunkName: "page-search-results" */ './PartnerSearchResults'
    ),
  {
    fallback: <Loading />
  }
);

export const LoginWall = loadable(
  () => import(/* webpackChunkName: "page-login-wall" */ './LoginWall'),
  {
    fallback: <Loading />
  }
);

export const Register = loadable(
  () => import(/* webpackChunkName: "page-register" */ './Register'),
  {
    fallback: <Loading />
  }
);

export const Checkout = loadable(
  () =>
    import(
      /* webpackChunkName: "page-checkout" */ './checkout/MonthlyCheckout'
    ),
  {
    fallback: <Loading />
  }
);

export const PartnerCheckout = loadable(
  () =>
    import(
      /* webpackChunkName: "page-checkout" */ './checkout/PartnerCheckout'
    ),
  {
    fallback: <Loading />
  }
);

export const ConsentsWall = loadable(
  () => import(/* webpackChunkName: "page-consent" */ './ConsentWall'),
  {
    fallback: <Loading />
  }
);

export const AddListing = loadable(
  () =>
    import(/* webpackChunkName: "page-add-listing" */ './dashboard/AddListing'),
  {
    fallback: <Loading />
  }
);

export const EditListing = loadable(
  () =>
    import(
      /* webpackChunkName: "page-edit-listing" */ './dashboard/EditListing'
    ),
  {
    fallback: <Loading />
  }
);

export const UpdatePrompt = loadable(
  () =>
    import(
      /* webpackChunkName: "page-update-prompt" */ './dashboard/UpdatePrompt'
    ),
  {
    fallback: <Loading />
  }
);

export const ParkingSpaces = loadable(
  () =>
    import(
      /* webpackChunkName: "page-parking-spaces" */ './dashboard/sODashboard/SODashboard'
    ),
  {
    fallback: <Loading />
  }
);

export const Availability = loadable(
  () =>
    import(
      /* webpackChunkName: "page-availability" */ './dashboard/Availability'
    ),
  {
    fallback: <Loading />
  }
);

export const VehiclePage = loadable(
  () =>
    import(/* webpackChunkName: "page-vehicle" */ './dashboard/VehiclePage'),
  {
    fallback: <Loading />
  }
);

export const PaymentsPage = loadable(
  () =>
    import(/* webpackChunkName: "page-payments" */ './dashboard/PaymentsPage'),
  {
    fallback: <Loading />
  }
);

export const WithdrawalPage = loadable(
  () =>
    import(
      /* webpackChunkName: "page-withdrawal" */ './dashboard/WithdrawalPage'
    ),
  {
    fallback: <Loading />
  }
);

export const BookingsMadePage = loadable(
  () =>
    import(
      /* webpackChunkName: "page-bookings-made" */ './dashboard/BookingsMadePage'
    ),
  {
    fallback: <Loading />
  }
);

export const SeasonTicketsDashboardPage = loadable(
  () => import('./dashboard/SeasonTicketsPage'),
  {
    fallback: <Loading />
  }
);

export const SeasonTicketDetailsPage = loadable(
  () => import('./dashboard/SeasonTicketDetailsPage'),
  {
    fallback: <Loading />
  }
);

export const BookingsDetailsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "page-booking-details" */ './dashboard/BookingDetailsPage'
    ),
  {
    fallback: <Loading />
  }
);

export const ProfilePage = loadable(
  () =>
    import(
      /* webpackChunkName: "page-edit-profile" */ './dashboard/ProfilePage'
    ),
  {
    fallback: <Loading />
  }
);

export const Logout = loadable(
  () => import(/* webpackChunkName: "page-logout" */ './Logout'),
  {
    fallback: <Loading />
  }
);

export const Agreements = loadable(
  () =>
    import(
      /* webpackChunkName: "page-agreements" */ './policies/AgreementsPage'
    ),
  {
    fallback: <Loading />
  }
);

export const Cancellation = loadable(
  () =>
    import(
      /* webpackChunkName: "page-cancellation" */ './policies/CancellationPage'
    ),
  {
    fallback: <Loading />
  }
);

export const Privacy = loadable(
  () => import(/* webpackChunkName: "page-privacy" */ './policies/PrivacyPage'),
  {
    fallback: <Loading />
  }
);

export const Cookie = loadable(() => import('./policies/CookiePage'), {
  fallback: <Loading />
});

export const Terms = loadable(
  () => import(/* webpackChunkName: "page-terms" */ './policies/TermsPage'),
  {
    fallback: <Loading />
  }
);

export const BookingReceivedPage = loadable(
  () => import('./dashboard/BookingReceivedPage'),
  {
    fallback: <Loading />
  }
);

export const Insurance = loadable(
  () =>
    import(/* webpackChunkName: "page-insurance" */ './policies/InsurancePage'),
  {
    fallback: <Loading />
  }
);

export const FastTrackPolicy = loadable(
  () =>
    import(
      /* webpackChunkName: "page-fast-track-policy" */ './policies/FastTrack'
    ),
  {
    fallback: <Loading />
  }
);

export const LoungesPolicy = loadable(
  () =>
    import(/* webpackChunkName: "page-lounges-policy" */ './policies/Lounges'),
  {
    fallback: <Loading />
  }
);

export const McdonaldsPolicy = loadable(
  () =>
    import(
      /* webpackChunkName: "page-mcdonalds-policy" */ './policies/McDonalds'
    ),
  {
    fallback: <Loading />
  }
);

export const ResetPassword = loadable(
  () => import(/* webpackChunkName: "page-reset-password" */ './ResetPassword'),
  {
    fallback: <Loading />
  }
);

export const PartnerHelp = loadable(() => import('./PartnerHelp'), {
  fallback: <Loading />
});

export const DeleteAccount = loadable(
  () => import(/* webpackChunkName: "page-delete-account" */ './DeleteAccount'),
  {
    fallback: <Loading />
  }
);

export const ListingPhotos = loadable(
  () => import('./dashboard/ListingPhotosPage'),
  {
    fallback: <Loading />
  }
);

export const InsuranceWhileParkedWebview = loadable(
  () => import('./dashboard/InsuranceWhileParkedWebview'),
  {
    fallback: <Loading />
  }
);

export const BookingsReceived = loadable(
  () => import('./dashboard/BookingsReceivedPage'),
  {
    fallback: <Loading />
  }
);

export const Dashboard = loadable(() => import('./dashboard/DashboardPage'), {
  fallback: <Loading />
});

export const EvSignupPage = loadable(() => import('./EvSignup'), {
  fallback: <Loading />
});

export const EvWelcomePage = loadable(() => import('./EvWelcome'), {
  fallback: <Loading />
});

export const EvEditPayment = loadable(() => import('./EvEditPayment'), {
  fallback: <Loading />
});

export const EvListingChargerDetails = loadable(
  () => import('./dashboard/EvListingChargerDetails'),
  {
    fallback: <Loading />
  }
);

export const ListingDetailsPage = loadable(
  () =>
    import(/* webpackChunkName: "page-listing-details" */ './ListingDetails'),
  { fallback: <Loading /> }
);

export const SuperUserPage = loadable(
  () => import(/* webpackChunkName: "super-user" */ './SuperUser'),
  {
    fallback: <Loading />
  }
);

export const ListingOnboardingPage = compose()(
  loadable(
    () =>
      import(
        /* webpackChunkName: "start-listing-onboarding" */ './dashboard/ListingOnboarding'
      ),
    {
      fallback: <Loading />
    }
  )
);

export const RentOutYourSpacePage = compose()(
  loadable(
    () =>
      import(
        /* webpackChunkName: "rent-out-your-space" */ './rentOutYourSpace/RentOutYourSpace'
      ),
    {
      fallback: <Loading />
    }
  )
);

export const CommunicationSettings = compose()(
  loadable(
    () =>
      import(
        /* webpackChunkName: "communication-settings" */ './dashboard/CommunicationSettings'
      ),
    {
      fallback: <Loading />
    }
  )
);

export const MessagingPage = loadable(
  () => import(/* webpackChunkName: "messaging" */ './dashboard/Messages'),
  {
    fallback: <Loading />
  }
);

export const MessageDetailsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "messaging-details" */ './dashboard/MessageDetailsPage'
    ),
  {
    fallback: <Loading />
  }
);
export const SendMessagePage = loadable(
  () =>
    import(
      /* webpackChunkName: "send-message" */ './dashboard/SendMessagePage'
    ),
  {
    fallback: <Loading />
  }
);

export const BillingsAndWithdrawalsPage = loadable(
  () => import('./dashboard/Billing'),
  {
    fallback: <Loading />
  }
);

export const AirportSearch = loadable(() => import('./AirportSearch'), {
  fallback: <Loading />
});
