import _debug from 'debug';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { show as showSnackbar } from '@justpark/snackbar/src/reducers/snackbar';
import getMarketingConsents from '@justpark/api/src/requests/getConsents';
import deleteAccountConsent from '@justpark/api/src/requests/deleteAccountConsent';
import createConsent from '@justpark/api/src/requests/createConsent';
import deleteCriteria from '@justpark/api/src/requests/deleteConsentCriteria';
import uniqBy from '../helpers/uniqBy';
// eslint-disable-next-line import/no-cycle
import type { State as AuthState, User } from './auth';

const debug = _debug('jp:reducers:consents');

const MARKETING_CONSENT = 'marketing';
type Consent = {
  name: string;
  accepted: boolean;
  body: string;
};

export type State = {
  loaded: boolean;
  loading: boolean;
  consented: Array<Consent>;
  declined: Array<Consent>;
  pending: Array<Consent>;
  consentsSaved: boolean;
  intro: string;
  footer: string;
  warning: string;
  modalWarning: string;
  deleted: boolean;
  revokeError: string;
  deleteAccount: boolean;
  hasActiveOrUpcomingBookings: boolean;
  balanceNotZero: boolean;
  isLoadingDeleteCriteria: boolean;
  fetchDeleteCriteriaError: string;
  hasFetchedDeleteCriteria: boolean;
};

export const initialState: State = {
  loaded: false,
  loading: false,
  consentsSaved: false,
  consented: [],
  declined: [],
  pending: [],
  intro: '',
  footer: '',
  warning: '',
  modalWarning: '',
  deleted: false,
  revokeError: '',
  deleteAccount: false,
  hasActiveOrUpcomingBookings: false,
  balanceNotZero: false,
  isLoadingDeleteCriteria: true,
  fetchDeleteCriteriaError: '',
  hasFetchedDeleteCriteria: false
};
export const getDeleteCriteria = createAsyncThunk(
  'consents/getDeleteCriteria',
  async (payload, { extra: { jpApiClient }, rejectWithValue }) => {
    try {
      const { data } = await jpApiClient(deleteCriteria());
      return data.data;
    } catch (e) {
      return rejectWithValue({
        message: e?.message || e,
        code: e?.error || 0
      });
    }
  }
);

export const deleteAccount = createAsyncThunk(
  'consents/deleteAccount',
  async (
    { queryParams, navigate, isWebview },
    { dispatch, extra: { t, jpApiClient }, rejectWithValue }
  ) => {
    const next = queryParams?.params?.next;
    try {
      const response = await jpApiClient(deleteAccountConsent());
      dispatch(
        showSnackbar(
          t(
            'deleteAccount:snackbarMessage',
            'You have successfully deleted your account!'
          )
        )
      );
      if (!isWebview) {
        navigate('/');
      } else {
        window.location = next || `${window.location.origin}/`;
      }
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.data || e);
    }
  }
);

const filterListing = (response, isSpaceOwner, newUser) => ({
  data: {
    ...response,
    pending: {
      data:
        isSpaceOwner && !newUser
          ? response.pending.data
          : response.pending.data.filter(({ name }) => name !== 'space_owner')
    }
  }
});

export const loadConsents = createAsyncThunk(
  'consents/loadConsents',
  async (payload, { rejectWithValue, extra: { jpApiClient } }) => {
    try {
      if (payload?.localData) {
        return filterListing(
          payload.localData,
          payload.isSpaceOwner,
          payload.newUser
        );
      }

      const response = await jpApiClient(getMarketingConsents());

      return payload?.shouldFilterListing
        ? filterListing(
            response.data.data,
            payload.isSpaceOwner,
            payload.newUser
          )
        : response.data;
    } catch (e) {
      return rejectWithValue({
        message: e?.message || e,
        code: e?.error || 0
      });
    }
  }
);

export function getConsents(
  queryParams: any = {},
  refresh: boolean,
  pathname = '',
  user?: User
) {
  return (dispatch, getStore) => {
    const state = getStore().consents;
    const { isSpaceOwner } = getStore().auth.user || user || {};
    const { consent, type } = queryParams;

    const shouldFilterListing =
      !consent && !type && pathname && pathname.includes('dashboard');
    const { newUser } = getStore().auth;

    const defaultData = {
      intro: { data: [{ name: 'intro', body: state.intro }] },
      pending: { data: state.pending },
      declined: { data: state.declined },
      consented: { data: state.consented },
      footer: { data: [{ name: 'footer', body: state.footer }] }
    };

    const localData =
      (!state.loading && !state.loaded) || refresh ? null : defaultData;

    return dispatch(
      loadConsents({
        shouldFilterListing,
        isSpaceOwner,
        newUser,
        localData,
        queryParams
      })
    );
  };
}

export const setMarketingConsent = createAsyncThunk(
  'consents/setMarketingConsent',
  async (
    payload,
    { rejectWithValue, getState, dispatch, extra: { jpApiClient, t } }
  ) => {
    try {
      const { setConsent, pathname, queryParams } = payload;

      const data = setConsent
        ? uniqBy(
            getState()
              .consents.consented.concat(
                getState().consents.declined.map(
                  ({ accepted, ...consent }) => ({
                    ...consent,
                    accepted: false
                  })
                )
              )
              .concat(
                pathname && pathname.includes('dashboard')
                  ? getState().consents.pending.map(
                      ({ accepted, ...consent }) => ({
                        ...consent,
                        accepted: false
                      })
                    )
                  : []
              ),
            ({ name }) => name
          )
        : getState()
            .consents.pending.filter(
              ({ name }: Consent) => name === MARKETING_CONSENT
            )
            .map((consent: Consent) => ({
              accepted: payload.accepted,
              ...consent
            }));

      const response = await jpApiClient(createConsent(data));

      if (setConsent) {
        if (
          pathname &&
          pathname.includes('dashboard') &&
          import.meta.env.SSR === false
        ) {
          window.location = `${window.location.origin}/dashboard`;
        } else {
          dispatch(
            getConsents(
              { ...queryParams }, // TODO: needs a destruct as there is a bug with encoded next urls
              true,
              pathname,
              getState().auth.user || undefined
            )
          );

          dispatch(
            showSnackbar(t('consents:prefUpdated', 'Preferences updated'))
          );
        }
      }

      if (!setConsent) {
        dispatch(getConsents({}, true));
      }

      return response.data;
    } catch (e) {
      return rejectWithValue({
        message: e?.message || e,
        code: e?.error || 0
      });
    }
  }
);

const consentsSlice = createSlice({
  name: 'consents',
  initialState,
  reducers: {
    toggleDeleteAccount: (state: State, action: PayloadAction) => ({
      ...state,
      deleteAccount: action.payload
    }),
    resetDeleteCriteria: (state: State): State => ({
      ...state,
      hasFetchedDeleteCriteria: false,
      balanceNotZero: false,
      hasActiveOrUpcomingBookings: false,
      isLoadingDeleteCriteria: true
    }),
    toggleConsent: (state: State, action: Action): State => {
      const n = action.payload;
      const pending = state.pending.filter(({ name }) => name === n)[0];
      const declinedC = state.declined.filter(({ name }) => name === n)[0];
      const consented = state.consented.filter(({ name }) => name === n)[0];
      if (pending) {
        return {
          ...state,
          pending: state.pending.filter(({ name }) => name !== n),
          consented: state.consented.concat([
            {
              ...pending,
              accepted: true
            }
          ])
        };
      }
      if (declinedC) {
        const { accepted, ...consent } = declinedC;
        return {
          ...state,
          declined: state.declined.filter(({ name }) => name !== n),
          consented: state.consented.concat([
            {
              ...consent,
              accepted: true
            }
          ])
        };
      }
      const { accepted, ...consent } = consented;
      return {
        ...state,
        consented: state.consented.filter(({ name }) => name !== n),
        declined: state.declined.concat([
          {
            ...consent,
            accepted: false
          }
        ])
      };
    },
    cancelWarning: (state: State, action: Action): State => ({
      ...state,
      modalWarning: action.payload
    }),
    setWarning: (state: State, action: Action): State => ({
      ...state,
      warning: action.payload
    }),
    setModalWarning: (state: State, action: Action): State => ({
      ...state,
      modalWarning: action.payload
    })
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadConsents.pending,
      (state: State): State => {
        debug(loadConsents.pending);
        return {
          ...state,
          loading: true,
          loaded: false,
          consented: [],
          declined: [],
          pending: []
        };
      }
    );
    builder.addCase(
      loadConsents.fulfilled,
      (state: State, action: Action): State => {
        debug(loadConsents.fulfilled);
        const { type, consent: queryConsent } =
          action.meta?.arg?.queryParams || {};

        const paramConsent = queryConsent || [];

        let requested =
          typeof paramConsent === 'string' ? [paramConsent] : paramConsent;

        const consents = action.payload.data;
        const update: State = {
          ...state,
          loading: false,
          loaded: true,
          consentsSaved: state.consented.length !== 0,
          intro: consents.intro.data[0].body,
          footer: consents.footer.data[0].body,
          pending: consents.pending.data,
          declined: consents.declined.data,
          consented: consents.consented.data.map((consent) => ({
            ...consent,
            accepted: true
          }))
        };

        if (type === 'add-listing') {
          requested = requested.concat(['space_owner']);
        }

        if (requested.length > 0) {
          return {
            ...update,
            pending: consents.pending.data.filter(({ name }) =>
              requested.includes(name)
            ),
            consented: [],
            declined: []
          };
        }

        return update;
      }
    );
    builder.addCase(
      loadConsents.rejected,
      (state: State, action: Action): State => {
        debug(loadConsents.rejected, action);
        return { ...state, loaded: false, loading: false };
      }
    );
    builder.addCase(
      setMarketingConsent.pending,
      (state: State): State => {
        debug(setMarketingConsent.pending);
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
    );
    builder.addCase(
      setMarketingConsent.fulfilled,
      (state: State): State => {
        debug(setMarketingConsent.fulfilled);
        return {
          ...state,
          consentsSaved: true,
          loading: false
        };
      }
    );
    builder.addCase(
      setMarketingConsent.rejected,
      (state: State, action: Action): State => {
        debug(setMarketingConsent.rejected, action);
        return { ...state, loading: false };
      }
    );
    builder.addCase(
      deleteAccount.pending,
      (state: State): State => {
        debug(deleteAccount.pending);
        return { ...state, loading: true, revokeError: '' };
      }
    );
    builder.addCase(
      deleteAccount.fulfilled,
      (): State => {
        debug(deleteAccount.fulfilled);
        return {
          ...initialState,
          deleted: true,
          loading: false
        };
      }
    );
    builder.addCase(
      deleteAccount.rejected,
      (state: State, action: Action): State => {
        debug(deleteAccount.rejected, action);

        const revokeError =
          action.payload?.meta?.user ||
          action.payload?.meta?.listing ||
          action.payload?.message ||
          '';

        return {
          ...state,
          loading: false,
          revokeError
        };
      }
    );
    builder.addCase(
      getDeleteCriteria.pending,
      (state: State): State => ({
        ...state,
        isLoadingDeleteCriteria: true,
        fetchDeleteCriteriaError: ''
      })
    );
    builder.addCase(
      getDeleteCriteria.fulfilled,
      (state: State, action: PayloadAction): State => ({
        ...state,
        ...action.payload,
        isLoadingDeleteCriteria: false,
        hasFetchedDeleteCriteria: true
      })
    );
    builder.addCase(
      getDeleteCriteria.rejected,
      (state: State, action: PayloadAction): State => ({
        ...state,
        ...action.payload,
        isLoadingDeleteCriteria: false,
        fetchDeleteCriteriaError: action.payload.message,
        hasFetchedDeleteCriteria: true
      })
    );
  }
});

export const {
  toggleDeleteAccount,
  resetDeleteCriteria,
  toggleConsent,
  cancelWarning,
  setWarning,
  setModalWarning
} = consentsSlice.actions;

export function setConsents(force: boolean, pathname, queryParams?: any, t) {
  return (
    dispatch: (a: any) => any,
    getState: () => { consents: State; auth: AuthState }
  ) => {
    const pending = getState().consents.pending.map(({ name }) => name);
    const consented = getState().consents.consented.map(({ name }) => name);
    const declined = getState().consents.declined.map(({ name }) => name);

    if (
      !force &&
      (pending.includes('space_owner') || declined.includes('space_owner')) &&
      consented.includes('standard') &&
      (getState().auth.user || {}).isSpaceOwner
    ) {
      return dispatch(
        setModalWarning(
          t(
            'consents:youWillLoselistingData',
            'You will lose any listing data you have saved unless you consent to keeping it.'
          )
        )
      );
    }
    if (!force && !consented.includes('standard')) {
      return dispatch(
        setWarning(
          t(
            'consents:youMustTick',
            "You must either tick the 'Standard' checkbox or 'I disagree with all of the above...' link below"
          )
        )
      );
    }

    return dispatch(
      setMarketingConsent({ setConsent: true, pathname, queryParams })
    );
  };
}

export const analyticsEventSubscribers = {
  [setMarketingConsent.fulfilled]: (
    action: Action,
    analytics: any,
    getState: () => any
  ) => {
    const state = getState();
    analytics.track('consents:success', {
      newUser: state.auth.newUser,
      marketing:
        state.consents.consented
          .map((consent) => consent.name === MARKETING_CONSENT)
          .filter((b) => b).length > 0
    });
  },
  [setMarketingConsent.rejected]: (action: Action, analytics: any) => {
    analytics.track('consents:fail', action);
  }
};

/**
 * Do we have a logged in user without loaded consents
 */
export const selectConsentsCanBeLoaded = (state) => {
  const isLoggedIn = state.auth.user?.id !== undefined;
  const consentsLoaded = state.consents.loaded;
  const consentsLoading = state.consents.loading;

  return isLoggedIn && !consentsLoaded && !consentsLoading;
};

export const selectConsentsHasLoaded = (state: { consents: State }) =>
  state.consents.loaded;

export const selectConsentsShouldAskForMarketingConsent = (state: {
  consents: State;
}) =>
  // Declined consents appear in both pending and declined.
  // Check marketing is pending and not declined
  state.consents.pending.find(
    (item: Consent) => item.name === MARKETING_CONSENT
  ) !== undefined &&
  state.consents.declined.find(
    (item: Consent) => item.name === MARKETING_CONSENT
  ) === undefined;

export const selectConsentsDeleteAccount = (state: { consents: State }) =>
  state.consents.deleteAccount;
export const selectConsentsDeleteAccountLoading = (state: {
  consents: State;
}) => state.consents.loading;
export const selectConsentsHasActiveOrUpcomingBookings = (state: {
  consents: State;
}) => state.consents.hasActiveOrUpcomingBookings;
export const selectConsentsHasPendingWithdrawals = (state: {
  consents: State;
}) => state.consents.balanceNotZero;
export const selectConsentsHasFetchedDeleteCriteria = (state: {
  consents: State;
}) => state.consents.hasFetchedDeleteCriteria;
export const selectConsentsIsLoadingDeleteCriteria = (state: {
  consents: State;
}) => state.consents.isLoadingDeleteCriteria;
export const selectConsentsRevokeError = (state: { consents: State }) =>
  state.consents.revokeError;

export default consentsSlice;
