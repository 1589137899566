/* eslint-disable react/require-default-props */
import React, { useEffect, Suspense, useMemo, lazy } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  useLocation,
  useNavigate,
  Outlet,
  ScrollRestoration,
  useMatches
} from '@not-remix-run/react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import VhResizer from '@justpark/ui/src/components/VhResizer';
import { toSnakeCase } from '@justpark/helpers/src/caseConverter/caseConverter';
import timeoutPromise from '@justpark/helpers/src/timeoutPromise/timeoutPromise';
import config from '../../config/config';
import withAnalytics from '../components/Decorators/withAnalytics';
import { setUser } from '../helpers/bugsnag/BugsnagClient';
import BrowserContext from '../context/BrowserContext';
import BrandContext from '../context/BrandContext';
import GlobalErrorBoundary from './GlobalErrorBoundary';
import { selectIsAppFeatureLimited } from '../stores/partner';
import useClearDataOnLogout from '../hooks/useClearDataOnLogout';
import LazyPromoModal from '../components/Common/LazyPromoModal';
import { logout } from '../stores/auth';
import { gtag } from '../helpers/Tracking/Analytics';
import { selectIsWebview } from '../stores/ui';
import useResetWhiteLabel from '../hooks/useResetWhiteLabel';
import OAuthTokenReceiver from '../components/Auth/OAuthTokenReceiver';
import { ampli } from '../helpers/Tracking/amplitude';
import { analyticsEventSubscribers as authAnalyticsEventSubscribers } from '../stores/auth';
import useBrowserStore from '../hooks/useBrowserStore';
import Awin from '../components/Awin';
import OneTrust from '../components/OneTrust';

/*
 * Hydrate the header and footer of the application separate to the main app.
 * By using webpack eager we do not generate a separate chunk for these components.
 * But by doing that they both resolve in the same task and are rendered in the same task.
 * Use a setTimeout 0 promise to break these into different tasks.
 */
const ConnectedHeader = lazy(() =>
  timeoutPromise(0).then(
    () => import(/* webpackMode: "eager" */ '../components/Global/Header')
  )
);

const Footer = lazy(() =>
  timeoutPromise(0).then(
    () =>
      import(/* webpackMode: "eager" */ '../components/Global/Footer/Footer')
  )
);

const ConnectedSnackBar = lazy(
  () =>
    import(
      '@justpark/snackbar/src/components/ConnectedSnackBar/ConnectedSnackBar'
    )
);
type Props = {
  isAppFeatureLimited: boolean;
  user?: any;
  brand?: {
    isWhiteLabel: boolean;
  };
  browser?: any;
  route: any;
};

const preventDefault = (e) => {
  e.preventDefault();
};

const useDisableAddToHomePrompt = () =>
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', preventDefault);

    return () => {
      window.removeEventListener('beforeinstallprompt', preventDefault);
    };
  }, []);

const AppHooks = () => {
  useDisableAddToHomePrompt();

  const navigate = useNavigate();

  useEffect(() => {
    if (import.meta.env.SSR === false && window.Cypress) {
      window.routerPush = navigate;
    }
  }, [navigate]);

  return null;
};

const App = (props: Props) => {
  const { isAppFeatureLimited, brand, user } = props;
  const location = useLocation();
  const { pathname } = location;
  const isWebview = useSelector(selectIsWebview);
  const browser = useBrowserStore();

  const matches = useMatches();

  const globalModifiers = useMemo(() => {
    // Tests to pass to matchRoutes that gives us an object with booleans on about what type of pages we are on
    // based on the metadata of the route, or its url, or parameters it has in the uri / querystring.
    // We use these to inform the global header and footer of their behaviour for these pages.
    const modifiers = {
      searchResults: (route) =>
        route.handle && route.handle.routeType === 'search',
      monthlyCheckout: (route) =>
        route.handle && route.handle.routeType === 'checkout',
      hidePrimaryNav: (route) =>
        route.handle && route.handle.headerHidePrimaryNav,
      minimalBackground: (route) =>
        route.handle && route.handle.headerMinimalBackground,
      darkMobileMenu: (route) =>
        route.handle && route.handle.headerDarkMobileMenu,
      hideFooter: (route) => route.handle && route.handle.hideFooter,
      hideFindParking: (route) => route.handle && route.handle.hideFindParking,
      hideCPM: (route) => route.pathname.startsWith('/dashboard'),
      hideHeader: (route) => route.handle && route.handle.hideHeader
    };
    Object.keys(modifiers).forEach((key) => {
      const test = modifiers[key];
      modifiers[key] = matches.some(test);
    });

    return modifiers;
  }, [matches]);

  const hideFooterIfNotLoggedIn = useMemo(
    () => matches.some((route) => route.handle && route.handle.requireAuth),
    [matches]
  );
  const isLoggedIn = props.user !== null;
  const hideFooter = hideFooterIfNotLoggedIn && !isLoggedIn;

  useClearDataOnLogout();
  useResetWhiteLabel();

  // Default canonical URL, may be overwritten by individual pages
  const canonicalUrl = pathname.endsWith('/')
    ? pathname.slice(0, -1)
    : pathname;

  return (
    // Wrap the App in a Suspense boundary so that we have an outer shell for the streaming render.
    // This ensures that we can send the outer HTML component even if this component tree causes an error
    <Suspense>
      <Helmet>
        <link
          rel="canonical"
          href={`https://www.justpark.com${canonicalUrl}`}
        />

        <meta name="theme-color" content="#21303e" />
      </Helmet>
      <ScrollRestoration />
      <AppHooks />
      <GlobalErrorBoundary>
        <BrandContext.Provider value={brand}>
          <BrowserContext.Provider value={browser}>
            <div>
              <div id="portal" />

              <Helmet {...config.app.head} />

              {!isWebview && !globalModifiers.hideHeader && (
                <Suspense>
                  <ConnectedHeader user={user} theme={globalModifiers} />
                </Suspense>
              )}

              <GlobalErrorBoundary>
                <div id="app-content">
                  <Outlet />
                </div>

                {!isWebview &&
                  !globalModifiers.hideFooter &&
                  !isAppFeatureLimited &&
                  !hideFooter && (
                    <Suspense>
                      <Footer />
                    </Suspense>
                  )}

                <Suspense>
                  <ConnectedSnackBar />
                </Suspense>

                <Suspense>
                  <LazyPromoModal />
                </Suspense>
              </GlobalErrorBoundary>
            </div>

            <Awin />
            <OneTrust />

            <VhResizer />
            <OAuthTokenReceiver />
          </BrowserContext.Provider>
        </BrandContext.Provider>
      </GlobalErrorBoundary>
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  brand: state.brand,
  isAppFeatureLimited: selectIsAppFeatureLimited(state)
});

const valueObservers = {
  bookingCount: [
    (state) => state?.auth?.user?.bookingSummary?.totalBookingsMade || 0,
    ({ state, analytics }) => {
      if (state && state.auth) {
        const defaultBookingSummary = {
          latestBookingDate: null,
          firstBookingDate: null,
          totalAppBookingsMade: 0,
          totalBookingsMade: 0
        };

        const { user } = state.auth;

        const defaultProperties = user
          ? user.bookingSummary
          : defaultBookingSummary;

        analytics.setDefaultProperties(toSnakeCase(defaultProperties));
      }
    }
  ],

  userId: [
    (state) => state?.auth?.user?.id || null,
    ({ state, analytics }) => {
      if (state && state.auth) {
        const { user } = state.auth;

        if (user && import.meta.env.SSR === false) {
          ampli.identify(user.id);

          if ('exponea' in window) {
            window.exponea.identify(user.id);
          }
        }

        setUser(user);

        const userId = state?.auth?.user?.id || null;
        const userEmail = state?.auth?.user?.email || null;
        const userFirstName = state?.auth?.user?.firstName || null;
        const userLastName = state?.auth?.user?.lastName || null;

        gtag({
          userId,
          userEmail,
          userFirstName,
          userLastName
        });

        const isLoggedIn = Boolean(user);

        analytics.setDefaultProperties({
          is_logged_in: isLoggedIn
        });
      }
    }
  ]
};

export const analyticsEventSubscribers = {
  [logout.fulfilled]: () => {
    if (import.meta.env.SSR === false && 'exponea' in window) {
      window.exponea.anonymize();
    }
  }
};

export default compose(
  connect(mapStateToProps),
  withAnalytics(valueObservers, {
    ...analyticsEventSubscribers,
    ...authAnalyticsEventSubscribers
  })
)(App);
