// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import Typography from "../Typography/index";
import "./pillLabel.scss";

type Props = {
  value?: number | string | null;
  label: string;
  className?: string;
  size?: "secondary" | "subtext";
  color?: "primary-inverse" | "primary";
  modifier?: "secondary";
};

const PillLabel = ({
  value = null,
  label,
  className = "",
  size = "secondary",
  color = "primary-inverse",
  modifier = undefined
}: Props) => {
  const baseClass = "c-pill";
  return (
    <div
      className={`${bem(baseClass, null, { modifier })} ${className}`}
      data-cy="c-pill-label-value"
    >
      {(value || value === 0) && (
        <Typography
          className={bem(baseClass, "value")}
          bold
          color={color}
          size={size}
        >
          {value}
        </Typography>
      )}
      <Typography className={bem(baseClass, "label")} color={color} size={size}>
        {label}
      </Typography>
    </div>
  );
};

export default PillLabel;
