// @ts-nocheck
import React, { useRef } from "react";
import {
  RovingTabIndexProvider,
  useFocusEffect,
  useRovingTabIndex
} from "react-roving-tabindex";
import bem from "@justpark/helpers/src/bem/bem";
import Typography from "../Typography";
import SortIcon from "../SortIcons";
import "./sortableTableHeading.scss";

type HeaderItem = {
  label: string | React.ReactNode;
  key: string;
  isSortable?: boolean;
  textAlign?: "left" | "center" | "right";
  isMobileHidden?: boolean;
};
type Props = {
  headerItems: Array<HeaderItem>;
  onSort?: (key: string, direction: string) => any;
  activeColumn?: {
    key: string;
    direction: "ASC" | "DESC";
  };
  bold?: boolean;
  size?: "secondary" | "subtext";
  className?: string;
};
type TableHeaderProps = {
  active: boolean;
  direction: "ASC" | "DESC" | null;
  el: HeaderItem;
  onClick: () => void;
  bold: boolean;
  size: "secondary" | "subtext";
};
const baseClass = "c-sortable-table-heading";
const thClass = (
  key,
  textAlign,
  isSortable = false,
  isMobileHidden = false
): string =>
  `${bem(baseClass, "th", {
    sortableHeading: isSortable,
    textAlign,
    isMobileHidden
  })} ${bem(baseClass, key)}`;
const TableHeader = ({
  el,
  active,
  direction,
  onClick,
  bold,
  size
}: TableHeaderProps) => {
  const ref = useRef(null);
  const [tabIndex, focused, rtiKeyDown, rtiClick] = useRovingTabIndex(ref);
  useFocusEffect(focused, ref);
  const ariaDirection = direction === "ASC" ? "ascending" : "descending";
  const color = bold ? "primary" : "muted";
  const isBold = bold || active;
  return (
    <Typography size={size} color={color} bold={isBold}>
      <th
        className={thClass(
          el.key,
          el.textAlign,
          el.isSortable,
          el.isMobileHidden
        )}
        aria-sort={active ? ariaDirection : null}
        key={el.key}
        onClick={(e) => {
          rtiClick(e);
          onClick();
        }}
        onKeyDown={(e) => {
          rtiKeyDown(e);
          if (e.key === " " || e.key === "Enter") {
            onClick();
          }
        }}
        ref={ref}
        role={el.isSortable ? "button" : null}
        tabIndex={tabIndex}
      >
        {el.label}
        {el.isSortable && (
          <SortIcon
            direction={direction}
            className={bem(baseClass, "icon", {})}
            active={active}
          />
        )}
      </th>
    </Typography>
  );
};
const SortableTableHeading = ({
  headerItems,
  onSort = () => {},
  activeColumn = {
    key: "",
    direction: "DESC"
  },
  bold = false,
  size = "secondary",
  className = ""
}: Props) => {
  return (
    <thead className={`${baseClass} ${className}`}>
      <tr>
        <RovingTabIndexProvider>
          {headerItems.map((el) => (
            <TableHeader
              active={activeColumn.key === el.key}
              className={el.className || null}
              direction={activeColumn.direction}
              bold={bold}
              size={size}
              el={el}
              key={el.key}
              onClick={() => {
                if (!el.isSortable) {
                  return;
                }
                const isActive = activeColumn.key === el.key;
                const currentDirection = isActive
                  ? activeColumn.direction
                  : "ASC";
                const newDirection =
                  currentDirection === "ASC" ? "DESC" : "ASC";
                onSort(el.key, newDirection);
              }}
            />
          ))}
        </RovingTabIndexProvider>
      </tr>
    </thead>
  );
};

export default SortableTableHeading;
