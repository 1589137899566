import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useSearchParams,
  useLoaderData,
  useNavigation
} from '@not-remix-run/react';

import Heading from '@justpark/ui/src/components/Heading';
import DropdownToolTip from '@justpark/ui/src/components/DropdownToolTip';
import Typography from '@justpark/ui/src/components/Typography';
import Pagination from '@justpark/ui/src/components/Pagination';
import LoadingWheel from '@justpark/ui/src/components/LoadingWheel';

import SearchFilters from './SearchFilters';
import TransactionsTable from './TransactionsTable';
import LegacyLink from '../../Common/LegacyLink';
import NoTransactions from './NoTransactions';

import styles from '../../../../static/scss/components/dashboard/billing/billingPageWrapper.module.scss';

const cypress = 'billings-page';

const BillingsPage = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const setSearchParams = useSearchParams()[1];

  const {
    transactions,
    pagination: { currentPage, totalPages, perPage, total }
  } = useLoaderData();

  const showTransactionTable = !!transactions.length;

  const onPaginationChange = (pageNumber: number) => {
    const newPage = pageNumber + 1;
    setSearchParams((params) => {
      params.set('page', newPage.toString());
      return params;
    });
  };

  const handleSetDates = (startDate: string, endDate: string) =>
    setSearchParams((params) => {
      params.set('start_date', startDate);
      params.set('end_date', endDate);
      return params;
    });

  const isLoading = navigation.state === 'loading';

  return (
    <div data-cy={cypress}>
      <Heading level={3} bold className={styles.subTitle}>
        <div>{t('billing:transactions', 'Transactions')}</div>
      </Heading>

      <div className={styles.wrapper}>
        <div className={styles.filterContainer}>
          <SearchFilters onSearch={handleSetDates} />
          <div className={styles.dropDown}>
            <DropdownToolTip
              label={t('cta:download', 'Download CSV')}
              content={
                <div className={styles.filterDownloadWrapperDropdown}>
                  <LegacyLink to="dashboard/billing/transactions/download">
                    {t('billing:transactions', 'Transactions')}
                  </LegacyLink>
                  <LegacyLink to="dashboard/billing/earnings/download">
                    {t('billing:earningsSummary', 'Earnings summary')}
                  </LegacyLink>
                </div>
              }
            />
          </div>
        </div>
        <Typography color="text-dark" className={styles.infoText}>
          <div>
            {t(
              'billing:summaryMessage',
              `Below is a summary of all transactions that have contributed to your
              JustPark earnings and payments. All payments in to your account and
              out of your JustPark account will be shown below.`
            )}
          </div>
        </Typography>
      </div>

      <div className={styles.transactionsContainer}>
        {isLoading && (
          <div className={styles.loader}>
            <LoadingWheel />
          </div>
        )}
        {showTransactionTable ? (
          <TransactionsTable transactions={transactions} />
        ) : (
          <div data-cy={`${cypress}-no-results`}>
            <NoTransactions />
          </div>
        )}
      </div>

      <Pagination
        className={styles.pagination}
        onChange={(pageNumber) => onPaginationChange(pageNumber)}
        currentPage={currentPage}
        pageCount={totalPages}
        showPagination={totalPages > 1}
        resultsPerPage={perPage}
        totalResults={total}
      />
    </div>
  );
};

export default BillingsPage;
