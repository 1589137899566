import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useRouteError } from '@not-remix-run/react';
import InfoBox from '@justpark/ui/src/components/InfoBox';
import Warning from '@justpark/ui/src/components/FontAwesome/Warning';
import BillingPageWrapper from '../../components/Dashboard/Billing/BillingPageWrapper';
import BillingsPage from '../../components/Dashboard/Billing/BillingsPage';

import styles from '../../../static/scss/components/dashboard/billing/billingPageWrapper.module.scss';

export function ErrorBoundary() {
  const error = useRouteError();

  return (
    <div className={styles.errorContainer}>
      <InfoBox cypress="billings-page-error" icon={<Warning />} type="red">
        {error.data}
      </InfoBox>
    </div>
  );
}

const Billing = () => (
  <>
    <Helmet
      title="Payment Transactions | JustPark - The Parking App"
      titleTemplate="%s"
    />
    <BillingPageWrapper>
      <BillingsPage />
    </BillingPageWrapper>
  </>
);

export default Billing;
