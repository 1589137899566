import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SortableTableHeading from '@justpark/ui/src/components/SortableTableHeading';
import Table from '@justpark/ui/src/components/Table';
import TableBody from '@justpark/ui/src/components/TableBody';
import useShouldEnableNative from '@justpark/ui/src/hooks/useShouldEnableNative';
import TransactionsTableRow from './TransactionsTableRow';

import styles from '../../../../static/scss/components/dashboard/messages/messagesTable.module.scss';

const generateHeadings = (t) => [
  {
    isSortable: false,
    key: 'withdrawalDate',
    label: t('billing:withdrawalDate', 'Withdrawal Date')
  },
  {
    isSortable: false,
    key: 'type',
    label: t('billing:type', 'Type')
  }
];

type Props = {
  transactions: Array<Message>;
};

const TransactionsTable = ({ transactions }: Props) => {
  const { t } = useTranslation();

  const isMobile = useShouldEnableNative();
  const headerItems = useMemo(() => {
    const amountHeading = {
      isSortable: false,
      key: 'amount',
      label: t('billing:amount', 'Amount')
    };
    if (isMobile) {
      return [...generateHeadings(t), amountHeading];
    }
    return [
      {
        isSortable: false,
        key: 'transactionId',
        label: t('billing:transactionId', 'Transaction ID')
      },
      ...generateHeadings(t),
      {
        isSortable: false,
        key: 'description',
        label: t('billing:description', 'Description')
      },
      amountHeading
    ];
  }, [isMobile, t]);

  return (
    <Table className={styles.table}>
      <SortableTableHeading
        headerItems={headerItems}
        bold
        className={styles.heading}
      />
      <TableBody>
        {transactions.map((transaction) => (
          <TransactionsTableRow
            key={transaction.id}
            transaction={transaction}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default TransactionsTable;
