import React from 'react';
import { useTranslation } from 'react-i18next';

import NoTransactionsIllustration from '@justpark/ui/src/components/illustrations/NoTransactions';
import NoResults from '../../Common/NoResults';

const NoTransactions = () => {
  const { t } = useTranslation();

  return (
    <NoResults
      headerText={t('billing:noTransaction', 'No transactions yet')}
      subText={t(
        'billing:yourTransaction',
        'Your transactions will appear here'
      )}
      image={<NoTransactionsIllustration />}
    />
  );
};

export default NoTransactions;
