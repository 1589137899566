const pathsNotRequired = ['/auth/consents/revoke'];

const requireAuthRedirect = (pathname: string, search: string) => {
  const redirectUrl = search.length > 0 ? `${pathname}${search}` : pathname;
  const path = encodeURIComponent(redirectUrl);

  if (pathsNotRequired.includes(pathname)) {
    return '/';
  }

  return `/auth/login/?routerNext=${path}&is_temp=1`;
};

export default requireAuthRedirect;
