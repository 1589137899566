import React, { useCallback, useEffect, useState } from "react";
import Typography from "../Typography";
import ChevronUp from "../FontAwesome/ChevronUp";
import ChevronDown from "../FontAwesome/ChevronDown";
import ToolTip from "../ToolTip";

import styles from "./dropdownToolTip.module.scss";

type Props = {
  label: string;
  content: any;
};

const DropdownToolTip = ({ label, content }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = useCallback(
    (e) => {
      const popper = document.getElementById("tippy-1");
      const targetInpopper = popper && popper.contains(e.target);
      if (targetInpopper) {
        return;
      }
      setIsOpen(false);
    },
    [setIsOpen]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, isOpen]);

  return (
    <button
      type="button"
      className={styles.dropdownToolTipBtn}
      onClick={() => setIsOpen(!isOpen)}
    >
      <ToolTip
        className={styles.dropdownToolTip}
        visible={isOpen}
        position="bottom-end"
        content={content}
        hideOnClickChild
      >
        <Typography
          className={styles.dropdownToolTipBtnText}
          bold
          size="primary"
        >
          {label}
        </Typography>

        {isOpen ? (
          <ChevronUp className={styles.dropdownToolTipBtnArrow} size={14} />
        ) : (
          <ChevronDown className={styles.dropdownToolTipBtnArrow} size={14} />
        )}
      </ToolTip>
    </button>
  );
};

export default DropdownToolTip;
