import React from 'react';
import timeoutPromise from '@justpark/helpers/src/timeoutPromise/timeoutPromise';
import getRoutes from './routes';
import App from './containers/App';
import { RouteErrorBoundary } from './containers/GlobalErrorBoundary';

const mapRoutesForUse = (routes) =>
  routes.map((item) => {
    const { children, element, action, errorElement } = item;

    const Element = element;
    const ErrorElement = errorElement;
    return {
      // Set a default loader on all routes so that react router always enters into a transition on navigation which is shown to reduce INP
      loader: () => timeoutPromise(0).then(() => null),
      ...item,
      element: element ? <Element /> : null,
      errorElement: errorElement ? <ErrorElement /> : null,
      children: children && mapRoutesForUse(children),
      action: action || (() => null),
      errorElement: errorElement ? <ErrorElement /> : null
    };
  });

export default () => {
  const routes = getRoutes();
  const browserRoutes = mapRoutesForUse(routes);

  return [
    {
      element: <App />,
      errorElement: <RouteErrorBoundary />,
      children: browserRoutes
    }
  ];
};
