// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import "./tableBody.scss";

type Props = {
  children: any;
  borderLight?: boolean;
};
const TableBody = ({ children, borderLight = false }: Props) => (
  <tbody
    className={`${bem("c-table-body", null, {
      borderLight
    })}`}
  >
    {children}
  </tbody>
);

export default TableBody;
