// @ts-nocheck
import React from "react";
import ReactPaginate from "react-paginate";
import Arrow from "../../svg/arrow.svg";
import Typography from "../Typography";
import PaginationPillLabel from "../PaginationPillLabel";
import PaginationPageJump from "../PaginationPageJump";
import "./pagination.scss";

type Props = {
  className?: string;
  pageCount?: number;
  pageRangeDisplayed?: number;
  marginPagesDisplayed?: number;
  onChange: (selected: number) => any;
  initialPage?: number;
  currentPage?: number;
  disableInitialCallback?: boolean;
  showPagination: boolean;
  resultType?: string;
  resultsPerPage?: number;
  totalResults?: number;
};
const Pagination = ({
  showPagination,
  className = "",
  pageCount = 10,
  pageRangeDisplayed = 3,
  marginPagesDisplayed = 1,
  onChange,
  initialPage = 0,
  currentPage = 1,
  disableInitialCallback = true,
  resultType,
  resultsPerPage = 20,
  totalResults = 20
}: Props) =>
  showPagination ? (
    <Typography size="secondary" color="light">
      <div className={`c-pagination ${className}`}>
        <ReactPaginate
          forcePage={currentPage - 1}
          pageCount={pageCount}
          pageRangeDisplayed={pageRangeDisplayed}
          marginPagesDisplayed={marginPagesDisplayed}
          previousLabel={<Arrow />}
          nextLabel={<Arrow />}
          onPageChange={({ selected }) => onChange(selected)}
          initialPage={initialPage}
          disableInitialCallback={disableInitialCallback}
        />

        {pageCount > 4 && (
          <PaginationPageJump
            className="c-pagination__page-jump"
            pageCount={pageCount}
            onChange={onChange}
          />
        )}

        {resultType && (
          <PaginationPillLabel
            resultType={resultType}
            currentPage={currentPage}
            resultsPerPage={resultsPerPage}
            totalResults={totalResults}
            className="c-pagination__pill-label"
          />
        )}
      </div>
    </Typography>
  ) : null;

export default Pagination;
