import React from 'react';
import Typography from '@justpark/ui/src/components/Typography';
import convertToDayjsIfDate from '@justpark/helpers/src/dates/convertToDayjsIfDate';

import styles from '../../../../static/scss/components/dashboard/messages/messageCard.module.scss';

type Message = {
  sender: string;
  message: string;
  createdAt: string;
  isRead: boolean;
};

const MessageCard = ({ message, sender, createdAt, isRead }: Message) => {
  const senderArray = sender.split(' ');

  return (
    <div
      className={`${styles.card} ${!isRead ? styles.background : ''}`}
      data-cy="message-card"
    >
      <div className={styles.container}>
        <div className={styles.avatar}>
          <Typography color="primary-inverse" className={styles.initials}>
            {`${senderArray[0].charAt(0)} ${senderArray[1].charAt(0)}`}
          </Typography>
        </div>
        <div className={styles.wrapper}>
          <Typography>{sender}</Typography>
          <Typography className={styles.date}>
            {convertToDayjsIfDate(createdAt).format('DD MMM YY, HH:mma')}
          </Typography>
        </div>
      </div>
      <Typography size="primary">
        <div className={styles.message}>{message}</div>
      </Typography>
    </div>
  );
};

export default MessageCard;
