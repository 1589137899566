// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import type { Node } from "react";
import "./tableCell.scss";

type Props = {
  children?: Node;
  cypress?: string | null;
  stretch?: boolean;
  className?: string;
  borderRight?: boolean;
  textAlign?: "left" | "center" | "right";
};

const TableCell = ({
  children,
  cypress = null,
  stretch = false,
  className = "",
  borderRight = false,
  textAlign = "left"
}: Props) => {
  const baseClass = "c-table-cell";
  const modifiers = {
    stretch,
    borderRight,
    textAlign
  };
  return (
    <td
      className={`${bem(baseClass, null, modifiers)} ${className}`}
      data-cy={cypress}
    >
      {children}
    </td>
  );
};

export default TableCell;
