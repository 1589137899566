import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from '@not-remix-run/react';
import Typography from '@justpark/ui/src/components/Typography';
import convertToDayjsIfDate from '@justpark/helpers/src/dates/convertToDayjsIfDate';

import styles from '../../../../static/scss/components/dashboard/messages/bookingSummary.module.scss';

const BookingSummary = () => {
  const { t } = useTranslation();

  const { aboutBooking } = useLoaderData();
  return (
    <div>
      <Typography bold className={styles.title}>
        <div>{t('messages:parkingSpace', 'Parking Space')}</div>
      </Typography>
      <Typography color="text-dark">{aboutBooking.listingTitle}</Typography>
      <div>
        <Typography bold className={styles.title}>
          <div>{t('messages:dates', 'Dates')}</div>
        </Typography>
        <Typography color="text-dark">
          <div>
            {convertToDayjsIfDate(aboutBooking.startDatetimeLocal).format(
              'DD MMM YY - HH:mma'
            )}
          </div>
        </Typography>
        <Typography color="text-dark">
          {convertToDayjsIfDate(aboutBooking.endDatetimeLocal).format(
            'DD MMM YY - HH:mma'
          )}
        </Typography>
      </div>
      <div>
        <Typography bold className={styles.title}>
          <div>{t('messages:total', 'Total')}</div>
        </Typography>
        <Typography color="text-dark">
          {aboutBooking.total.formatted}
        </Typography>
      </div>
    </div>
  );
};

export default BookingSummary;
