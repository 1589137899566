import React, { useEffect, useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import {
  useLoaderData,
  useSearchParams,
  useNavigation,
  useRouteError
} from '@not-remix-run/react';

import { useFetchers } from 'react-router-dom';

import Heading from '@justpark/ui/src/components/Heading';
import Typography from '@justpark/ui/src/components/Typography';
import LoadingWheel from '@justpark/ui/src/components/LoadingWheel';
import Pagination from '@justpark/ui/src/components/Pagination';
import Link from '@justpark/ui/src/components/Link';
import Button from '@justpark/ui/src/components/Button';
import InfoBox from '@justpark/ui/src/components/InfoBox';
import Warning from '@justpark/ui/src/components/FontAwesome/Warning';

import DashboardPageWrapper from '../../components/Dashboard/DashboardPageWrapper';
import MessageCard from '../../components/Dashboard/Messages/MessageCard';
import SendMessageModal from '../../components/Dashboard/Messages/SendMessageModal';
import BookingSummary from '../../components/Dashboard/Messages/BookingSummary';

import styles from '../../../static/scss/pages/messageDetails.module.scss';

export function ErrorBoundary() {
  const error = useRouteError();
  return (
    <div className={styles.errorContainer}>
      <InfoBox
        className={styles.error}
        cypress="messages-error"
        icon={<Warning />}
        type="red"
      >
        {error.data}
      </InfoBox>
    </div>
  );
}

const cypress = 'message-details-page';

const MessageDetailsPage = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const messageContainerRef = useRef<HTMLDivElement>(null);

  // to be replaced by useFetcher when the remix migration is complete
  const fetchers = useFetchers();
  const setSearchParams = useSearchParams()[1];
  const {
    messages: {
      data: messages,
      meta: { pagination }
    },
    aboutBooking,
    withUser
  } = useLoaderData();

  const [openSendMessageModal, setOpenSendMessageModal] = useState(false);
  const isLoading = navigation.state === 'loading';
  const onPaginationChange = (page) =>
    setSearchParams((params) => {
      params.set('page', page + 1);
      return params;
    });

  // to be replaced by useFetcher when the remix migration is complete
  const fetcher = fetchers.find(({ key }) => key === 'send-message');

  useEffect(() => {
    if (fetcher?.data?.success || !isLoading) {
      setOpenSendMessageModal(false);
      messageContainerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [fetcher?.data, messageContainerRef, isLoading]);

  return (
    <div>
      <Helmet
        title={t(
          'messages:messageDetailsTitle',
          'Discussion with | JustPark - The Parking App'
        )}
        titleTemplate="%s"
      />
      <DashboardPageWrapper
        className={styles.dashboardPageWrapper}
        cypress={cypress}
      >
        <div className={styles.container}>
          <div className={styles.headingContainer}>
            <Heading className={styles.heading} level={1} bold>
              {t('messages:discussionWithSender', {
                sender: withUser.displayName
              })}
            </Heading>
          </div>

          <div className={styles.wrapper}>
            <div className={styles.messagesContainer}>
              {isLoading && (
                <div className={styles.loader}>
                  <LoadingWheel />
                </div>
              )}
              <div className={styles.messages} ref={messageContainerRef}>
                {messages?.map(
                  (message: {
                    id: number;
                    message: string;
                    createdAt: string;
                    isRead: boolean;
                    sender: string;
                  }) => (
                    <div key={message.id} className={styles.message}>
                      <MessageCard
                        message={message.message}
                        sender={message.sender}
                        createdAt={message.createdAt}
                        isRead={message.isRead}
                      />
                    </div>
                  )
                )}
              </div>
              <Button
                cypress={`${cypress}-open-modal`}
                onClick={() => setOpenSendMessageModal(true)}
                className={styles.button}
              >
                {t('messages:sendAnotherMessage', 'Send another message')}
              </Button>
              {pagination?.totalPages > 1 && (
                <Pagination
                  className={styles.pagination}
                  onChange={(pageNumber) => onPaginationChange(pageNumber)}
                  pageCount={pagination.totalPages}
                  initialPage={pagination.currentPage - 1}
                  showPagination={!!pagination.totalPages}
                />
              )}
            </div>

            <SendMessageModal
              isOpen={openSendMessageModal}
              onClose={() => setOpenSendMessageModal(false)}
              bookingId={aboutBooking.id}
            />

            <div className={styles.summary}>
              <div className={styles.bookingSummary}>
                <Typography size="h3" bold>
                  <div>{t('messages:bookingSummary', 'Booking Summary')}</div>
                </Typography>
                <BookingSummary />
              </div>
              <div className={styles.precautions}>
                <Typography size="h3" bold>
                  <div>
                    {t('messages:safetyPrecautions', 'Safety Precautions')}
                  </div>
                </Typography>

                <div>
                  <Typography className={styles.text} color="light">
                    <div>
                      {t(
                        'messages:bookingWarning',
                        `Some property owners may try and take your booking
                              offline or insist that you do not need to complete
                              the booking to have a confirmed parking space.`
                      )}
                    </div>
                  </Typography>
                  <Typography className={styles.text} color="light">
                    <div>
                      {t(
                        'messages:bookingProtection',
                        `Any booking not completed through JustPark is not
                              protected by our driver protection guarantee, is
                              not guaranteed a space and you may show up to find
                              that your space has been booked by somebody else.`
                      )}
                    </div>
                  </Typography>
                  <Typography className={styles.text} color="light">
                    <div>
                      <Trans i18nKey="messages:reportRequest">
                        If the property owner is requesting that you don&apos;t
                        complete and pay for the booking through JustPark,
                        please report this by{' '}
                        <Link href="https://support-uk.justpark.com/hc/en-gb">
                          <Typography color="brand">clicking here.</Typography>
                        </Link>
                      </Trans>
                    </div>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardPageWrapper>
    </div>
  );
};

export default MessageDetailsPage;
