import React from 'react';

import { Link } from '@not-remix-run/react';
import { useTranslation } from 'react-i18next';
import Heading from '@justpark/ui/src/components/Heading';
import Typography from '@justpark/ui/src/components/Typography';
import DashboardPageWrapper from '../DashboardPageWrapper';

import styles from '../../../../static/scss/components/dashboard/billing/billingPageWrapper.module.scss';

type Props = {
  children: any;
};
const BillingPageWrapper = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <DashboardPageWrapper
      className={styles.dashboardPageWrapper}
      cypress="billing-page"
    >
      <div>
        <Heading level={2} bold className={styles.title}>
          {t('dashboard:BillingsAndWithdrawals', 'Billings & Withdrawals')}
        </Heading>
        <div className={styles.linksContainer} data-cy="billing-withdraw">
          <Heading level={3} bold className={styles.subTitle}>
            <div>{t('dashboard:Withdrawals', 'Withdrawals')}</div>
          </Heading>
          <div className={styles.links}>
            <Link to="/dashboard/billing/withdrawal" className={styles.link}>
              <Typography color="brand">
                <div>
                  {t('navigation:withdrawEarnings', 'Withdraw Earnings')}
                </div>
              </Typography>
            </Link>
            <Link to="/dashboard/billing/withdrawal" className={styles.link}>
              <Typography color="brand">
                <div>
                  {t(
                    'navigation:manageWithdrawalMethods',
                    'Manage Withdrawal Methods'
                  )}
                </div>
              </Typography>
            </Link>
          </div>
        </div>
        <div className={styles.linksContainer} data-cy="billing-payments">
          <Heading level={3} bold className={styles.subTitle}>
            {t('dashboard:Payments', 'Payments')}
          </Heading>
          <Link to="/dashboard/billing/payment-sources" className={styles.link}>
            <Typography color="brand">
              <div>
                {t('navigation:managePaymentMethods', 'Manage Payment Methods')}
              </div>
            </Typography>
          </Link>
        </div>
      </div>
      <div className={styles.linksContainer} data-cy="billing-payments">
        <Heading level={2} bold className={styles.subTitle}>
          {t('dashboard:Payments', 'Payments')}
        </Heading>
        <Link to="/dashboard/billing/payment-sources">
          <Typography color="brand">
            {t('navigation:managePaymentMethods', 'Manage Payment Methods')}
          </Typography>
        </Link>
      </div>
      {children}
    </DashboardPageWrapper>
  );
};

export default BillingPageWrapper;
