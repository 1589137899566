// @ts-nocheck
import React, { useCallback, useState } from "react";
import SearchInput from "../SearchInput";

type Props = {
  pageCount: number;
  onChange: () => void;
  className: string;
};
const PaginationPageJump = ({ pageCount, onChange, className }: Props) => {
  const [pageNumber, setPageNumber] = useState("");
  const onHandlePageChange = useCallback(() => {
    const page = Number(pageNumber);
    const selected = page < 1 ? 0 : page - 1;
    onChange(selected);
  }, [pageNumber, onChange]);
  const onHandlePageNumber = useCallback(
    (val) => {
      if (Number(val) > pageCount) return;
      setPageNumber(val);
    },
    [setPageNumber, pageCount]
  );
  return (
    <SearchInput
      value={pageNumber}
      onChange={onHandlePageNumber}
      onSearch={onHandlePageChange}
      disableAutoSearch
      placeholder="Enter page number"
      className={className}
      inputMode="numeric"
      type="number"
    />
  );
};
export default PaginationPageJump;
