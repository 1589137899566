import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from '@justpark/ui/src/components/Button';
import SingleDatePickerDropDown from '@justpark/ui/src/components/SingleDatePickerDropDown';

import convertToDayjs from '@justpark/helpers/src/dates/convertToDayjs';

import dayjs from 'dayjs';
import isDateToday from '@justpark/helpers/src/dates/isDateToday';
import styles from '../../../../static/scss/components/dashboard/billing/searchFilters.module.scss';

type Props = {
  onSearch: (startDate: string, endDate: string) => void;
};

const SearchFilters = ({ onSearch }: Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const today = dayjs().format('YYYY-MM-DD').toString();
  const [dates, setDates] = useState({
    from: '',
    until: ''
  });

  useEffect(() => {
    const initialFromDate = searchParams.get('start_date') || '';
    const initialUntilDate = searchParams.get('end_date') || '';

    setDates({
      from: initialFromDate,
      until: initialUntilDate
    });
  }, [searchParams]);

  const handleDateChange = (key: 'from' | 'until', value: string) => {
    setDates((prev) => {
      const newDates = { ...prev, [key]: value };

      if (
        key === 'from' &&
        dayjs(newDates.until).isBefore(dayjs(newDates.from))
      ) {
        newDates.until = '';
      }

      return newDates;
    });
  };

  const isDisabled = !dates.until || !dates.from;

  return (
    <div className={styles.wrapper} data-cy="billings-search-filter">
      <SingleDatePickerDropDown
        date={dates.from}
        onChange={(value) => handleDateChange('from', value)}
        placeholder={t('dates:from', 'From')}
        hideErrorCross
        error=""
        className={styles.date}
        t={t}
        min={null}
        max={today}
      />
      <SingleDatePickerDropDown
        date={dates.until}
        onChange={(value) => handleDateChange('until', value)}
        placeholder={t('dates:until', 'Until')}
        t={t}
        className={styles.date}
        error=""
        hideErrorCross
        max={today}
        min={convertToDayjs(dates.from).toString()}
      />
      <Button
        className={styles.button}
        outline
        onClick={() => onSearch(dates.from, dates.until)}
        cypress="billings-search-submit"
        disabled={isDisabled}
      >
        {t('cta:search', 'Search')}
      </Button>
    </div>
  );
};

export default SearchFilters;
