// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import type { Element } from "react";
import SortableTableHeading from "../SortableTableHeading";
import TableBody from "../TableBody";
import "./table.scss";

type Props = {
  children:
    | [Element<typeof SortableTableHeading>, Element<typeof TableBody>]
    | Element<typeof TableBody>;
  className?: string;
  alternatingBgColor?: boolean;
};
const Table = ({
  children,
  className = "",
  alternatingBgColor = false
}: Props) => (
  <table
    className={`${bem("c-table", null, {
      "alternating-bg-color": alternatingBgColor
    })} ${className}`}
  >
    {children}
  </table>
);

export default Table;
