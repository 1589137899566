// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import Typography from "../Typography";
import "./textArea.scss";

type Props = {
  onChange: (a: string) => void;
  className?: string;
  onFocus?: () => void;
  placeholder?: string;
  id?: string;
  children: any;
  cypress?: string;
  label?: string;
  disabled?: boolean;
  name?: string;
};
const baseClass = "c-textarea";
const TextArea = ({
  onChange,
  children,
  placeholder = "",
  className = "",
  onFocus = () => {},
  id = "",
  cypress = null,
  label = "",
  disabled = false,
  name = ""
}: Props) => (
  <>
    {label && (
      <Typography
        size="secondary"
        bold
        color="light"
        className={bem(baseClass, "label")}
      >
        <div>{label}</div>
      </Typography>
    )}
    <textarea
      onFocus={onFocus}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      className={`${baseClass} ${className}`}
      value={children}
      id={id}
      data-cy={cypress}
      disabled={disabled}
      name={name}
    />
  </>
);
export default TextArea;
