// @ts-nocheck
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);

export const transformLocale = (locale) => {
  if (locale === "en" || locale === "") {
    return "en-GB";
  }

  return locale;
};

const convertToDayjsWithTimezone = (
  date: Date | string,
  locale = "en-GB",
  tz = "Europe/London"
) => {
  const isoPatternOffset = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}([+-])\d{2}:\d{2}$/;

  if (isoPatternOffset.test(<string>date)) {
    return dayjs(date).locale(transformLocale(locale)).tz(tz);
  }
  return dayjs(date).locale(transformLocale(locale));
};

export default convertToDayjsWithTimezone;
