import React, { useMemo } from 'react';
import { Navigate, Outlet, useLocation } from '@not-remix-run/react';
import { useSelector } from 'react-redux';
import { selectUserIsLoggedIn } from '../../stores/auth';
import requireAuthRedirect from '../../helpers/requireAuthRedirect';

const RequiredAuth = () => {
  const user = useSelector(selectUserIsLoggedIn);
  const { pathname, search } = useLocation();

  const redirectUrl = useMemo(() => requireAuthRedirect(pathname, search), [
    pathname,
    search
  ]);

  if (!user) {
    return <Navigate to={redirectUrl} />;
  }

  return <Outlet />;
};

export const shouldRevalidate = () => false;

export const handle = {
  requireAuth: true
};

export default RequiredAuth;
