// @ts-nocheck
import React from "react";
import PillLabel from "../PillLabel";
import Typography from "../Typography/index";
import "./paginationPillLabel.scss";

type Props = {
  className?: string;
  resultType?: string;
  currentPage: number;
  resultsPerPage: number;
  totalResults: number;
};
const PaginationPillLabel = ({
  resultType = "",
  currentPage,
  totalResults,
  resultsPerPage,
  className = ""
}: Props) => {
  const baseClass = "c-pagination-pill-label";
  const limit =
    currentPage * resultsPerPage > totalResults
      ? totalResults
      : currentPage * resultsPerPage;
  const offset = (currentPage - 1) * resultsPerPage + 1;
  const value = `${offset}-${limit} of ${totalResults}`;
  return (
    <div className={`${baseClass} ${className}`}>
      <Typography color="muted" size="subtext">
        <div
          className={`${baseClass}__label`}
          data-cy="pagination-pill-label-text"
        >
          RESULTS
        </div>
      </Typography>
      <PillLabel value={value} label={resultType} />
    </div>
  );
};

export default PaginationPillLabel;
